import React, {useState, useEffect} from 'react'
import Layout from '../components/Layout'
import Banner from '../components/Banner'
import FormDetailsSection from '../components/Contact/FormDetailsSection'
import MapComponent from '../components/General/MapComponent'
import { graphql } from 'gatsby'
import { fetchData } from '../utitlities/helperFunctions'

const ContactPage = ({ data }) => {
  const [ContactData,SetData] = useState()     
  useEffect(() => { 
    fetchData(`contact`)
    .then(x => SetData(x) )         
}, []); 

  const contactPageData = ContactData ? ContactData : data.contact.edges[0].node
  const bannerImageData = {url: data.bannerImage.edges[0].node.childImageSharp.original.src}
  const pageSeo = contactPageData.Seo
  return (
    <Layout pageSeo={pageSeo}>
      <Banner
        imageUrl={true}
        bannerId="contact-banner"
        heading={contactPageData.heading}
        subHeading={contactPageData.subHeading}
        secondaryHeadings={contactPageData.headingHighlights}
        imageComponent={bannerImageData}

      />
      <FormDetailsSection />
      <MapComponent url={contactPageData.mapUrl} />
    </Layout>
  )
}

export const query = graphql`
query ContactPageQuery {
  contact:allApiContact {
    edges {
      node {
        Seo {
          description
          title
        }
        heading
        headingHighlights
        subHeading
        mapUrl
      }
    }
  }
  bannerImage: allFile(
    filter: {sourceInstanceName: {eq: "images"}, name: {eq: "ContactBanner"}}
  ) {
    edges {
      node {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
}
`

export default ContactPage
