import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { contentMaxWidth } from '../../utitlities/Configurations'
import Form from './Form'
import Details from './Details'

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "1rem 1rem 2rem",
        [theme.breakpoints.up("sm")]: {
            padding: "4rem 2rem 0px"
        }
    }
}))

export default function FormDetailsSection() {
    const classes = useStyles()
    return <div id='top-services' >
        <div className={classes.container}>
            <Grid container spacing={4} justifyContent='space-between'>
                <Grid item xs={12} md={7}>
                    <Details />
                </Grid>
                <Grid item xs={12} md={5}>
                    <Form showTitle={true} showFullWidth={true} title='Details' contactId={'contact-form'} wrapWholeDiv={true} />
                </Grid>
            </Grid>
        </div>
    </div>
}
