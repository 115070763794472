import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { contentMaxWidth } from '../../utitlities/Configurations'

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: contentMaxWidth,
        margin: 'auto',
        padding: "1rem 1rem 2rem",
        [theme.breakpoints.up("sm")]:{
            padding:'2rem 2rem 0px'
        }
    },
    iframe: {
        width: '100%',
        height: 'auto',
        minHeight: "200px",
        borderRadius: '6px',
        border:'none',
        [theme.breakpoints.up('sm')]: {
            minHeight: "500px"
        }
    }
}))

export default function MapComponent({ url = '', title = 'Location Map', className, styles = {} }) {
    const classes = useStyles()
    return <div className={classes.container}>
        <iframe src={url} title={title} allowFullScreen  
        className={clsx(classes.iframe, className)} style={styles} />
    </div>
}