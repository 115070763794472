import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import TitleComponent from '../General/TitleComponent'
import { Timeline, TimelineItem, TimelineSeparator, TimelineOppositeContent, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab'
import { paletteConfig } from '../../utitlities/Configurations'

export default function Details() {
    const details = [
        {
            heading: "1. Contact your Superhero",
            description: "Fill the contact form with a few simple details. We will then connect to discuss in details"
        },
        {
            heading: "2. We will use our Superpower",
            description: "We deep dive to understand your brand, your customers & curate a custom digital plan! We take your valuable inputs, discuss prices & finally seal the deal"
        },
        {
            heading: "3. Watch your brand Fly",
            description: "You can sit & relax! We work our magic with our experienced superheroes! Voila! Your brand is flying too!"
        }
    ]
    return <div >
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TitleComponent
                    title="Wondering how this works?"
                    subHeading='Here are 3 quick steps'
                    titleAlign='flex-start'
                    subHeadingVariant='body1'
                    subHeadingColor={paletteConfig.primary.main}
                    isFullWidth={true}
                />
            </Grid>
            <Grid item xs={12}>
                <Timeline>

                    {
                        details.map((item, index) => {
                            return <TimelineItem key={index}>
                                <TimelineOppositeContent style={{ flex: '0', padding: '0px' }} >
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant="outlined" />
                                    {
                                        index !== details.length - 1 &&
                                        <TimelineConnector />
                                    }

                                </TimelineSeparator>
                                <TimelineContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography>{item.heading}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='body2'>{item.description}</Typography>
                                        </Grid>
                                    </Grid>
                                </TimelineContent>

                            </TimelineItem>
                        })
                    }
                </Timeline>
            </Grid>
        </Grid>
    </div>
}

